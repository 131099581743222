import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import { OverlayTrigger, Tooltip, Table } from 'react-bootstrap'
import moment from 'moment'
import { Element } from 'react-scroll'
import SostaOnlineApiService from 'services/SostaOnlineApiService'
import queryString from 'query-string'
import { scroller } from 'react-scroll'
import classNames from 'classnames'
import { MARK_DURATION, MARK_ENABLE_START_DATE, MARK_EXPIRATION_DATE, MARK_RENEW_NOTIFY_DAYS } from 'libs/settings'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as solidIcons from '@fortawesome/free-solid-svg-icons'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actions as authActions } from '../../../redux/modules/Auth'
import { Oval } from 'react-loader-spinner'
import { MARK_TYPE_ZTL_DISABILI, MARK_TYPE_FAMILY, STATUS_ACTIVE, STATUS_CEASED, STATUS_EXPIRED, STATUS_RENEWED, STATUS_WAIT_APPROVE, STATUS_WAIT_PAYMENT, getMarkZone, getSelectedEndDate, getSelectedStartDate } from 'libs/marks'
import { NEWS_SECTIONS, getHtmlMsgFromString, getNewsStyle } from 'libs/news'
import { Carousel } from 'react-responsive-carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { checkEndDate } from 'libs/utils'
const fileDownload = require('js-file-download');

const mapStateToProps = (state) => {
  return {
    stateAuth: state.auth
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    authActions: bindActionCreators(authActions, dispatch)
  }
}

const TextUser = (props) => <div className={props.bold ? "text-bold-user" : "text-regular-user"} style={{ textAlign: 'center' }}>{props.children}</div>

class UserAreaMarks extends Component {

  static propTypes = {
    onOpenRenewMarkModal: PropTypes.func.isRequired,
    onCloseRenewMarkModal: PropTypes.func.isRequired,
    renewingMark: PropTypes.object,
    isModalOpen: PropTypes.bool
  }

  /**
   * --------------------------------------------------------------
   * @param {*} props 
   */
  constructor(props) {
    super(props)

    this.state = {
      sortedMarks: null,
      markRenewNotifyDays: null,
      markEnableStartDate: false,
      highlightObjects: [],
      downloading: null,
      news: [],
      settings: []
    }

    this.showRenewButton = this.showRenewButton.bind(this)
    this.showReceiptButton = this.showReceiptButton.bind(this)
    this.onMarkReceiptDownload = this.onMarkReceiptDownload.bind(this)
    this.showOrder = this.showOrder.bind(this)

  }

  /**
   * --------------------------------------------------------------
   * 
   */
  componentDidMount() {
    if (this.props.user && this.props.user.marks) {

      this.setState({ sortedMarks: this.props.user.marks.sort((a, b) => (a.id > b.id) ? -1 : 1) })

      SostaOnlineApiService.fetchPublicSettings().then(
        settings => {
          if (settings != null && settings.data != null && settings.data.length > 0) {

            this.setState({ settings: settings.data })

            settings.data.map(setting => {
              if (setting.name === MARK_RENEW_NOTIFY_DAYS) {
                //console.log("SETTING",setting) 
                this.setState({ markRenewNotifyDays: parseInt(setting.value) })
              }

              else if (setting.name === MARK_ENABLE_START_DATE) {
                //console.log("SETTING",setting) 
                this.setState({ markEnableStartDate: setting.value == "1" })
              }

            })
          }
        }
      )
        .catch(error => {
          console.log("ERROR", error)
        })
    }

    const queryParams = queryString.parse(this.props.location.search)
    if (queryParams.scrollTo) {
      setTimeout(() => {
        this.scrollTo(queryParams.scrollTo)
        this.highlightElement(queryParams.scrollTo)
      }, 300)
    }

    SostaOnlineApiService.fetchNewsMe(NEWS_SECTIONS.USERMARKS.value)
      .then(news => {
        this.setState({ news: news.data })
      })

  }

  /**
   * --------------------------------------------------------------
   * @param {*} element 
   */
  scrollTo(element) {
    scroller.scrollTo(element, {
      duration: 1000,
      delay: 100,
      smooth: true
    })
  }

  /**
   * --------------------------------------------------------------
   * @param {*} element 
   */
  highlightElement(element) {
    setTimeout(() => {
      this.setState({
        highlightObjects: [element]
      }, () => {
        setTimeout(() => {
          this.setState({
            highlightObjects: []
          })
        }, 1000)
      })
    }, 1100)
  }

  /**
   * --------------------------------------------------------------
   * @param {*} prevProps 
   */
  componentDidUpdate(prevProps) {
    if (this.props.user !== prevProps.user && this.props.user && this.props.user.marks) {
      this.setState({ sortedMarks: this.props.user.marks.sort((a, b) => (a.id > b.id) ? -1 : 1) })

      SostaOnlineApiService.fetchNewsMe(NEWS_SECTIONS.USERMARKS.value)
        .then(news => {
          this.setState({ news: news.data })
        })
    }
  }

  /**
   * --------------------------------------------------------------
   * @param {*} item 
   * @returns 
   */
  getMarkTypeRender(item) {
    const today = moment()
    let endDateM = moment(item.endDate)
    const isExpired = today.diff(endDateM, 'days') > 0

    var render = {
      id: '',
      type: '',
      descr: '',
      tooltipContent: "",
      titleBoxStyle: '',
      showPrice: false,
      showRangeDate: false,
      showChangeActiveVehicle: false,
      downloadMark: false,
      downloadMarkRelease: false,
      downloadMarkReceipt: false,
      showModify: false,
      download: false
    }


    if (item.active) {

      //console.log('item.status',item.id, item.status)

      if (item.ceased) {
        if (item.status === 'ACTIVE' && item.renewed && moment().isBefore(moment(item.endDate))) {
          render = {
            id: "R",
            type: STATUS_RENEWED,
            tooltipContent: "Questo permesso è stato rinnovato, è utilizzabile fino al termine della sua validità",
            titleBoxStyle: 'user-card-head-r',
            showPrice: false,
            showRangeDate: true,
            downloadMark: false,
            downloadMarkRelease: false,
            downloadMarkReceipt: true,
            showModify: false,
            download: item.MarkType.printableByUser

          }
        }
        else {
          render = {
            id: "C",
            type: STATUS_CEASED,
            tooltipContent: "Questo è un permesso cessato, non è più utilizzabile.",
            titleBoxStyle: 'user-card-head-c',
            showPrice: false,
            showRangeDate: true,
            downloadMark: false,
            downloadMarkRelease: false,
            downloadMarkReceipt: false,
            showModify: false,
            download: false

          }
        }
      }
      else if (((item.paid && item.price > 0) || (parseFloat(item.price) == 0 || !item.price))) {
        if ((!item.ceased && !isExpired)) {
          render = {
            id: "A",
            type: STATUS_ACTIVE,
            tooltipContent: "Questo è un permesso attivo ed utilizzabile.",
            titleBoxStyle: 'user-card-head-a',
            showPrice: false,
            showRangeDate: true,
            showChangeActiveVehicle: false,
            downloadMark: false,
            downloadMarkRelease: false,
            downloadMarkReceipt: true,
            showModify: true,
            download: item.MarkType.printableByUser
          }          

          if (item.virtual)
            render.showChangeActiveVehicle = true

        }
        else if (!item.ceased && isExpired) {
          render = {
            id: "S",
            type: STATUS_EXPIRED,
            tooltipContent: "Questo è un permesso scaduto, non è ancora stato cessato ma non è più utilizzabile.",
            titleBoxStyle: 'user-card-head-c',
            showPrice: false,
            showRangeDate: true,
            downloadMark: false,
            downloadMarkRelease: false,
            downloadMarkReceipt: true,
            showModify: true,
            download: false
          }
        }
      }
      else if (!item.paid && item.price > 0) {
        render = {
          id: "AP",
          type: STATUS_WAIT_PAYMENT,
          tooltipContent: "Questo permesso si attiverà una volta pagato il saldo.",
          titleBoxStyle: 'user-card-head-p',
          showPrice: this.props.user.userConfirmed ? true : false,
          showRangeDate: true,
          downloadMark: false,
          downloadMarkRelease: false,
          downloadMarkReceipt: false,
          showModify: false,
          download: false
        }
      }

    }

    else if (!item.active) {

      var showRangeDate = this.state.markEnableStartDate || item.MarkType.type == MARK_TYPE_FAMILY

      render = {
        id: "AA",
        type: STATUS_WAIT_APPROVE,
        tooltipContent: "Questo sarà il tuo permesso definitivo. Al momento dell'approvazione ti arriverà una e-mail all'indirizzo fornito.",
        titleBoxStyle: 'user-card-head-at',
        showPrice: false,
        showRangeDate: showRangeDate,
        downloadMark: false,
        downloadMarkRelease: false,
        downloadMarkReceipt: false,
        download: false
      }
    }

    render.descr = 'N° ' + item.id + ' ' + render.type.toUpperCase()

    return render;

  }

  /**
   * --------------------------------------------------------------
   * @param {*} item 
   * @param {*} render 
   * @returns 
   */
  showRenewButton(item, render) {    
    return render.showModify &&
      item.MarkType.renewable &&
      moment(item.endDate).startOf('day').diff(moment().startOf('day'), 'days') <= this.state.markRenewNotifyDays &&
      // moment(item.endDate).startOf('day').diff(moment().startOf('day'), 'days') > this.state.markRenewNotifyDays &&
      !item.renewed &&
      item.MarkType.type !== MARK_TYPE_FAMILY
  }

  /**
   * --------------------------------------------------------------
   * @param {*} item 
   * @param {*} render 
   * @returns 
   */
  showReceiptButton(item, render) {
    //console.log("containers/UserArea/Mark/UserAreaMarks showReceiptButton: item:",item)
    //console.log("containers/UserArea/Mark/UserAreaMarks showReceiptButton: render:",render)
    return render.downloadMarkReceipt && item.MarkOrder && item.MarkOrder.Order && item.MarkOrder.Order.Receipt
  }

  /**
   * --------------------------------------------------------------
   * @param {*} item 
   * @param {*} render 
   * @returns 
   */
  showPrintButton(item, render) {
    render.download = item.MarkType.printableByUser
    return render.download
  }

  /**
   * --------------------------------------------------------------
   * @param {*} item 
   */
  onMarkReceiptDownload(item) {
    this.setState({ downloading: item.id })
    this.props.onMarkReceiptDownload(item.id)
      .then(result => {
        this.setState({ downloading: null })
      })
      .catch(error => {
        this.setState({ downloading: null })
      })
  }


  onDownloadMark(mark) {
    SostaOnlineApiService.downloadMarkMe(mark.id).then(data => {
      fileDownload(data, 'permesso-' + mark.issuedDate + '.pdf', 'application/pdf')
    })
  }

  onMarkDownload(item) {
    this.setState({ downloading: item.id })
    SostaOnlineApiService.downloadMyMark(item.id).then(data => {
      fileDownload(data, 'permesso-' + item.id + '-' + item.issuedDate + '.pdf', 'application/pdf')
    })
      .then(result => {
        this.setState({ downloading: null })
      })
      .catch(error => {
        this.setState({ downloading: null })
      })
  }
  /**
   * --------------------------------------------------------------
   * @param {*} mark 
   * @returns 
   */
  getSortedMarkNotes(mark) {

    var notes = []

    if (mark) {

      if (mark.MarkNotes != null && mark.MarkNotes.length > 0) {
        notes = mark.MarkNotes.sort((a, b) => (a.id > b.id) ? 1 : -1)
      }

    }

    return notes

  }


  /**
   * --------------------------------------------------------------
   * @param {*} mark 
   * @param {*} render 
   * @returns 
   */
  showPlateNumbers(mark, render) {

    return (
      <React.Fragment>
        <div style={{ padding: 0, paddingTop: "0.2em", paddingBottom: "0.2em" }} className="col-md-12">
          <span>
            TARG{mark.Vehicles.length > 1 ? 'HE' : 'A'}&nbsp;
            <strong>
              {
                mark.Vehicles.map((vehicle, index) => {
                  if (vehicle.archived) {
                    return (
                      <span key={index} style={{ textDecoration: "line-through" }}>{vehicle.plate_number}&nbsp;
                        <OverlayTrigger shouldUpdatePosition={true} placement="top" overlay={<Tooltip id="tooltip-vehicle-0">il veicolo {vehicle.plate_number} è stato eliminato</Tooltip>} >
                          <i className="glyphicon glyphicon-info-sign">{ }</i>
                        </OverlayTrigger>
                        &nbsp;
                      </span>
                    )
                  }
                  else {
                    return (<span key={index}>{vehicle.plate_number}&nbsp;&nbsp; </span>)
                  }
                })
              }
            </strong>
          </span>
        </div>
        {render.showChangeActiveVehicle && mark.Vehicles.length > 1 &&
          <div style={{ ppadding: 0, paddingTop: "0.2em", paddingBottom: "0.2em" }} className="col-md-12">
            <div className="mark-numberplate-change-button change-plate-button">
              <strong><span className="span-prova">TARGA ATTIVA {mark.ActiveVehicle.plate_number}</span>
              </strong>
              <div className="change-plate-button-margin" >
                <a onClick={() => this.props.onShowChooseMarksVehicleModal(mark)} className="btn mainBtn">
                  <span style={{ marginRight: 8 }} className="glyphicon glyphicon-pencil" />MODIFICA TARGA ATTIVA
                </a>
              </div>
            </div>
          </div>
        }
      </React.Fragment>
    )

  }

  /**
   * --------------------------------------------------------------
   * @param {*} mark 
   * @param {*} categoryId 
   * @returns 
   */
  getMarkTypeCategory(mark, categoryId) {
    var result = { circolazione: null, periodo: null, sosta: null, color: null }

    if (mark && mark.MarkType && mark.MarkType.Categories) {

      var categories = mark.MarkType.Categories.filter(item => item.id === categoryId)

      if (categories && categories.length > 0 && categories[0].MarkTypeCategories && categories[0].MarkTypeCategories) {
        result = categories[0].MarkTypeCategories
      }

    }

    return result
  }

  /**
   * --------------------------------------------------------------
   * @param {*} item 
   */
  getEndDate(item) {

    var endDate = moment(item.endDate).format("L")

    if (item != null && item.startDate != null && item.paid != "1") {

      var settings = this.state.settings.filter(setting => (setting.name == MARK_DURATION + item.MarkType.id || setting.name == MARK_EXPIRATION_DATE + item.MarkType.id))

      if (settings != null && settings.length > 0 && item.MarkType != null) {

        if (item.startDate && moment(item.startDate).isBefore(moment())) {

          if (settings[0].name == MARK_DURATION + item.MarkType.id) {
            endDate = moment().add(parseInt(settings[0].value), "day").format("L")
          }
          else if (settings[0].name == MARK_EXPIRATION_DATE + item.MarkType.id) {
            endDate = moment(settings[0].value).format("L")
          }
        }

      }

    }

    return endDate
  }

  /**
   * --------------------------------------------------------------
   * @param {*} item 
   */
  getStartDate(item) {
    //console.log('UserAreaMarks: getStartDate: item', item);
    var sSD = item.selectedStartDate
    var SD = item.startDate
    if (item != null && SD != null && item.paid != "1") {

      if (sSD != null && sSD != undefined) {
        if (!moment(sSD).isBefore(moment(SD))) {
          SD = moment(sSD).format("L")
        }
      } else if (moment(SD).isBefore(moment())) {
        SD = moment().format("L")
      }
    }
    return moment(SD).format("L")
  }

  /**
   * --------------------------------------------------------------
   * @param {*} item 
   */
  showActiveVehicleMessage2(mark) {
    var show = false;

    if (mark.virtual && mark && mark.Vehicles) {
      var validVehicles = mark.Vehicles.filter(v => !v.archived).length

      if (validVehicles && validVehicles.length > 1)
        show = true
    }

    return show

  }

  /**
   * -------------------------------------------------------------------------
   * 
   */

  showOrder(mark) {
      const MarkOrders = mark.MarkOrder;
      // Verifica se MarkOrder esiste e non è vuoto
      if (MarkOrders && (1 + 1 == 1)) {
        const countMO = MarkOrders.length;
        return (
          <div className="row hidden-xs hidden-sm" style={{
            display: "flex",
            flex: "auto",
            alignContent: "center",
            alignSelf: "center"
          }}>
            <div className="col-md-12 hidden-xs hidden-sm" style={{
              margin: ".5vh .5vw",
              borderRadius: "15px",
              backgroundColor: "#eafff061",
              boxShadow: "3px 3px 12px 12px #e6f5ebad",
              padding: "2vh 2vw 0",
              maxWidth: "100%"
            }}>
              <p className="title hidden-xs hidden-sm">{countMO > 1 ? "Sono presenti " + countMO + " Ordini" : countMO == 0 ? "Non sono presenti Ordini" : "È presente un Ordine"}</p>
              <Table responsive className="hidden-xs hidden-sm">
                <tbody>
                  {
                    countMO > 1 ?
                      MarkOrders.map((item, index) => {
                        const isLast = index === MarkOrders.length - 1;
                        return (
                          <React.Fragment key={item.id}>
                            <tr>
                              <td className="text-left">
                                <strong>Mark Order:</strong> {item.id} &nbsp; <strong>Order:</strong> {item.Order.id}
                              </td>
                            </tr>
                            <tr>
                              <td className="text-left">
                                <strong>Data:</strong> {moment(item.createdAt).format("DD.MM.YYYY HH:mm")}
                              </td>
                            </tr>
                            <tr>
                              <td className="text-left">
                                <strong>Gateway:</strong> {item.Order.gateway} &nbsp; <strong>Status:</strong> {item.Order.status}
                              </td>
                            </tr>
                            <tr>
                              <td className="text-left">
                                <strong>PaymentId:</strong> {item.Order ? item.Order.paymentId : 'N/D'}
                              </td>
                            </tr>
                            {item.Order && item.Order.Receipt && item.Order.Receipt.id &&
                              <tr>
                                <td className="text-left">
                                  <strong>Payment:</strong> € {item.Order.Receipt.total} {item.Order.Receipt.method}
                                </td>
                              </tr>
                            }
                            {
                              !isLast &&
                              <tr>
                                <td className="text-left">
                                  <hr />
                                </td>
                              </tr>
                            }
                          </React.Fragment>
                        )
                      })
                      :
                      <React.Fragment key={MarkOrders.id}>
                        <tr>
                          <td className="text-left">
                            <strong>Mark Order:</strong> {MarkOrders.id} &nbsp; <strong>Order:</strong> {MarkOrders.Order.id}
                          </td>
                        </tr>
                        <tr>
                          <td className="text-left">
                            <strong>Data:</strong> {moment(MarkOrders.createdAt).format("DD.MM.YYYY HH:mm")}
                          </td>
                        </tr>
                        <tr>
                          <td className="text-left">
                            <strong>Gateway:</strong> {MarkOrders.Order.gateway} &nbsp; <strong>Status:</strong> {MarkOrders.Order.status}
                          </td>
                        </tr>
                        <tr>
                          <td className="text-left">
                            <strong>PaymentId:</strong> {MarkOrders.Order ? MarkOrders.Order.paymentId : 'N/D'}
                          </td>
                        </tr>
                        {MarkOrders.Order && MarkOrders.Order.Receipt && MarkOrders.Order.Receipt.id &&
                          <tr>
                            <td className="text-left">
                              <strong>Payment:</strong> € {MarkOrders.Order.Receipt.total} {MarkOrders.Order.Receipt.method}
                            </td>
                          </tr>
                        }
                      </React.Fragment>
                  }
                </tbody>
              </Table>
            </div>
          </div>
        );
      } else {
        return ""; //<p>Nessun ordine associato</p>;
      }
  }

  /**
 * --------------------------------------------------------------
 * Formatta la endData secondo il fuso orario di Greenwich
 * @param {*} data
 * @returns ( data )
 */
  formatEndDataFuse(data) {
    //2028-02-02T23:59:59.000Z
    let dataNewFormat = data.split('T');
    let dataFinalFormat = dataNewFormat[0].split('-');
    return dataFinalFormat[2]+"/"+dataFinalFormat[1]+"/"+dataFinalFormat[0]
  }


  /**
   * --------------------------------------------------------------
   * @returns 
   */
  render() {

    const {
      onOpenRenewMarkModal,
      user
    } = this.props

    const {
      downloading,
      news,
      settings
    } = this.state

    var render = {};
    var ricevuta = null;
    var rinnovo = null;
    var download = null;
    var ordini = null;

    return (
      <div className="row">
        <div className="col-md-8">
          <div className="panel panel-default panel-border-login">
            <h2 className="login-title">PERMESSI</h2>
            <div className="panel-body">
              <div className="panel-body-header" style={{
                marginBottom: "2vh",
                marginLeft: "15px",
                marginRight: "15px",
                borderRadius: "15px",
                backgroundColor: "#aef6c361",
                boxShadow: "3px 3px 12px 12px #e6f5ebad",
                padding: "2vh 2vw 1vh",
                height: "auto",
                minHeight: "10vh",
                alignItems: "center",
                alignContent: "center"
              }}>
                <div className="col-md-3" style={{ paddingLeft: 0 }}>
                  <FontAwesomeIcon size="6x" className="sectionIcons" icon={solidIcons.faWallet} />
                </div>
                <div className="col-md-9">
                  {user && user.userConfirmed &&
                    <NavLink to="/user-area/marks/create" style={{ textDecoration: "none" }}>
                      <div className="bnt user-card-btn1" style={{ marginBottom: "2.5rem" }}>
                        <strong>RICHIEDI PERMESSO</strong>
                        <FontAwesomeIcon className="mainIconsRevNoAction" size="2x" icon={solidIcons.faCirclePlus} />
                      </div>
                    </NavLink>}
                </div>
              </div>
              <div className="col-md-12">

                {this.state.sortedMarks && this.state.sortedMarks.map(item => {

                  render = this.getMarkTypeRender(item)
                  ricevuta = this.showReceiptButton(item, render)
                  rinnovo = this.showRenewButton(item, render)
                  download = this.showPrintButton(item, render)
                  ordini = this.showOrder(item)

                  return (
                    <div key={item.id} className={classNames({ 'animated flash': this.state.highlightObjects.indexOf(`mark-${item.id}`) !== -1 })}>
                      <Element name={'mark-' + item.id} />
                      <div className="user-card" style={{ marginBottom: "2.5rem" }}>
                        <div className={render.titleBoxStyle}>
                          <div className="user-card-head">
                            <h5 className="user-card-title">PERMESSO: <strong>{render.descr}{' '}</strong>
                              <OverlayTrigger placement="top" overlay={<Tooltip id={'tooltip-' + item.id}>{render.tooltipContent}</Tooltip>}>
                                <span className="question-icon glyphicon glyphicon-question-sign pull-right">{ }</span>
                              </OverlayTrigger>
                            </h5>
                          </div>
                        </div>
                        {downloading === item.id &&
                          <React.Fragment>
                            <div className="user-card-body col-md-12">
                              <div className="row" style={{ padding: "2em", height: "20em" }}>
                                <Oval secondaryColor="#02afff" color="#174c88" height={40} width="100%" />
                              </div>
                            </div>
                            <div className="user-card-footer">&nbsp;&nbsp;</div>
                          </React.Fragment>
                        }
                        {downloading !== item.id &&
                          <React.Fragment>
                            <div className="user-card-body">

                              <div className="row">
                                <div style={{ padding: 0, paddingTop: "0.2em", paddingBottom: "0.2em" }} className="col-md-12">
                                  <strong style={{ color: item.MarkType.color == null || item.MarkType.color.toUpperCase() == "#FFFFFF" ? "#5a5a5a" : item.MarkType.color }} >{item.MarkType.label}</strong>
                                </div>
                                <div style={{ padding: 0, paddingTop: "0.2em", paddingBottom: "0.2em" }} className="col-md-12">
                                  CATEGORIA <strong>{item.Category.label.toUpperCase()}</strong>
                                </div>
                              </div>

                              <div className="row">
                                <div style={{ padding: 0, paddingTop: "0.2em", paddingBottom: "0.2em" }} className="col-md-12">
                                  ZONE <strong>{getMarkZone(user, item)}</strong>
                                </div>
                                {item.periodo &&
                                  <div style={{ padding: 0, paddingTop: "0.2em", paddingBottom: "0.2em" }} className="col-md-6">
                                    PERIODO <strong>{item.periodo}</strong>
                                  </div>
                                }
                                {item.sosta &&
                                  <div style={{ padding: 0, paddingTop: "0.2em", paddingBottom: "0.2em" }} className="col-md-6">
                                    SOSTA <strong>{item.sosta}</strong>
                                  </div>
                                }
                                <div style={{ padding: 0, paddingTop: "0.2em", paddingBottom: "0.2em", marginBottom: 5, marginTop: 5 }} className="col-md-12">
                                  <span>NUM. IDENTIFICATIVO <strong>{item.id}</strong></span><br />
                                  {item.fatherMark && <span>DERIVATO DA PERMESSO N. <strong>{item.fatherMark}</strong></span>}
                                </div>
                                {this.showPlateNumbers(item, render)}
                                {item.note != null && item.note.length > 0 &&
                                  <div style={{ padding: 0, marginBottom: 5 }} className="col-md-12">
                                    <strong>{render.type}</strong> causa  <strong>{item.note}</strong>
                                  </div>
                                }
                              </div>

                              {render.type == 'ATTIVO' && item.issuedDate != null &&
                                <div className="row">
                                  <div style={{ padding: 0, paddingTop: "0.2em", paddingBottom: "0.2em", marginBottom: 5, marginTop: 5 }} className="col-md-12">
                                    <span>EMESSO IL <strong>{moment(item.issuedDate).format('L')}</strong></span>
                                  </div>
                                </div>
                              }

                              {(render.showRangeDate) &&
                                <div className="row">
                                  <div style={{ padding: 0, paddingTop: "0.2em", paddingBottom: "0.2em", marginBottom: 5 }} className="col-md-12">
                                    {(item.startDate || item.endDate || item.selectedStartDate) &&
                                      <span>VALIDO</span>
                                    }
                                    {item.startDate != null &&
                                      <span> DAL <strong> {moment(item.startDate).format('L')}</strong></span>
                                    }
                                    {item.startDate == null && item.selectedStartDate != null &&
                                      <span> DAL <strong>{getSelectedStartDate(item).format("L")}</strong></span>                                      
                                    }
                                    {item.endDate != null &&
                                      <span> FINO AL <strong>{this.formatEndDataFuse(item.endDate)}</strong></span>
                                      // <span> AL <strong>{checkEndDate(getSelectedEndDate(item, settings).format("L"))}</strong></span>
                                   }
                                    {item.endDate == null && item.selectedStartDate != null && getSelectedEndDate(item, settings) != null &&
                                      <span> FINO AL <strong>{checkEndDate(getSelectedEndDate(item, settings).format("L"))}</strong></span>
                                    }
                                  </div>
                                </div>
                              }

                              <div className="row">
                                {item.virtual &&
                                  <div style={{ padding: 0 }} className="col-md-12">
                                    <br />
                                    <strong>IL PERMESSO SI ATTIVA ALL'APPROVAZIONE DA PARTE DELL'OPERATORE.</strong><br />
                                    <strong>UNA VOLTA APPROVATO, NON NECESSITA DI ALCUN TAGLIANDO DA ESPORRE SUL VEICOLO.</strong><br />
                                    Le verifiche di validità del medesimo verranno effettuate sulla targa tramite sistema informatizzato.<br />
                                    <br /><br />
                                  </div>
                                }
                                {this.showActiveVehicleMessage2(item) &&
                                  <div style={{ padding: 0 }} className="col-md-12">
                                    <strong>IL PERMESSO E' ATTIVO SOLO SU UN VEICOLO PER VOLTA</strong>. <br />
                                    <strong>Selezionare la TARGA ATTIVA</strong> ogni volta necessiti attivare il permesso sul veicolo di interesse.
                                    <br /><br />
                                  </div>
                                }
                                {(render.downloadMark || render.downloadMarkRelease) &&
                                  <div className="button-download">
                                    {render.downloadMark &&
                                      <div className="col-md-6 col-btn-display">
                                        <OverlayTrigger placement="top" overlay={<Tooltip id={'tooltip-download-mark-' + item.id} >{render.tooltipContent}</Tooltip>}>
                                          <a onClick={() => this.props.onDownloadMark(item)} className="btn mainBtn">
                                            <FontAwesomeIcon size="1x" icon={solidIcons.faDownload} />&nbsp;&nbsp;PERMESSO
                                          </a>
                                        </OverlayTrigger>
                                      </div>
                                    }
                                    {render.downloadMarkRelease &&
                                      <div className="col-md-6 col-btn-display">
                                        <OverlayTrigger placement="top" overlay={<Tooltip id={'tooltip-download-mark-release-' + item.id}>Questo è il <strong>Modulo di Rilascio</strong>.<br /> Scaricalo, stampalo, compilalo e portalo con te quando andrai a ritirare il tuo permesso definitivo</Tooltip>}>
                                          <a onClick={() => this.props.onDownloadMarkRelease(item)} className="btn mainBtn">
                                            <FontAwesomeIcon size="1x" icon={solidIcons.faDownload} />&nbsp;&nbsp;ACCETTAZIONE
                                          </a>
                                        </OverlayTrigger>
                                      </div>
                                    }
                                  </div>
                                }
                              </div>
                              
                              {render.showPrice &&
                                <>
                                  <div className="row">
                                    <div style={{ padding: "0 5px", marginBottom: "5px" }} className="col-md-12">
                                      <span>PREZZO: <strong>€ {Number(item.price).toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</strong></span>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <NavLink to={'/user-area/pay/mark/' + item.id} className="btn mainBtn">
                                      <FontAwesomeIcon size="1x" className="sectionIconsRev" icon={solidIcons.faEuro} />&nbsp;&nbsp;
                                      PAGA PERMESSO
                                    </NavLink>
                                  </div>
                                </>
                              }

                              {(item.status === 'PENDING_EDITABLE') && (
                                <React.Fragment>
                                  <div className="row">

                                    {this.getSortedMarkNotes(item).map((note, index) =>
                                      <React.Fragment key={"note-" + note.id}>
                                        {index === 0 &&
                                          <h4><strong>Note di questa richiesta:</strong></h4>
                                        }

                                        {index === 0 &&
                                          <div key="note-header" className="row noteRowNoMargin" >
                                            <div className="col-md-3 col-sm-3 col-xs-12 text-left " ><strong>Data</strong></div>
                                            <div className="col-md-9 col-sm-9 col-xs-12 text-left " ><strong>Nota</strong></div>
                                          </div>
                                        }

                                        <div className="row noteRowNoMargin" >
                                          <div className="col-md-3 col-sm-3 col-xs-12 text-left "><strong>{moment(note.createdAt).fromNow()}</strong></div>
                                          <div className="col-md-9 col-sm-9 col-xs-12 text-left "><strong>{note.notes}</strong></div>
                                        </div>
                                      </React.Fragment>
                                    )}

                                  </div>
                                  <div className="row">
                                    <div className="col-md-6 col-btn-display">
                                      <NavLink to={`/user-area/marks/edit/${item.id}`} className="btn mainBtn">
                                        MODIFICA
                                      </NavLink>
                                    </div>
                                  </div>
                                </React.Fragment>
                              )}
                              {(render.downloadMarkReceipt || render.showModify) &&
                                <div className="row">
                                  <div style={{ padding: 0, marginBottom: 5, marginTop: 5 }} className={"col-md-12 " + (rinnovo ? '' : 'btnOnCenter')}>
                                    {ricevuta &&
                                      <div className={"col-btn-display col-sm-4"}>
                                        <a onClick={() => this.onMarkReceiptDownload(item)} className="btn mainBtn" style={{ textAlign: 'center' }}>
                                          <FontAwesomeIcon size="1x" icon={solidIcons.faDownload} />&nbsp;&nbsp;RICEVUTA
                                        </a>
                                      </div>
                                    }
                                    {rinnovo &&
                                      <div className={"col-btn-display " + (ricevuta ? download ? 'col-sm-4' : 'col-sm-8' : 'col-sm-12')}>
                                        <a onClick={() => onOpenRenewMarkModal(item)} className="btn mainBtn">
                                          <FontAwesomeIcon size="1x" icon={solidIcons.faRepeat} />&nbsp;&nbsp;RINNOVA</a>
                                      </div>
                                    }
                                    {download &&
                                      <div className={"col-btn-display " + (ricevuta ? rinnovo ? 'col-sm-4' : 'col-sm-8' : 'col-sm-12')}>
                                        <a onClick={() => this.onMarkDownload(item)} className="btn mainBtn">
                                          <FontAwesomeIcon size="1x" icon={solidIcons.faPrint} />&nbsp;&nbsp;PERMESSO</a>
                                      </div>
                                    }
                                  </div>
                                </div>
                              }
                            </div>
                            <div className="user-card-footer" style={{ paddingTop: "15px" }}>{ordini}</div>
                          </React.Fragment>
                        }

                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          {user.categories[0].name == "Reseller" ?
            [
              <div>
                <div key="1" id="reseller-information-containerxssm" className="information-container hidden-xs hidden-sm" style={{ display: "flex", justifyContent: "center" }}>
                  <div style={{ width: "85%" }}>
                    <div style={{ marginTop: 15, marginBottom: 10, display: "flex", alignItems: "center" }}>
                      <span className="threesom-span">
                        <FontAwesomeIcon size="2x" className="sectionIcons" icon={solidIcons.faUserTie} />
                      </span>
                      <div>
                        <TextUser bold>AREA PERMESSI RIVENDITORE NON ANCORA DISPONIBILE</TextUser>
                        <TextUser>
                          Il Vostro status di RIVENDITORE deve essere confermato dall'amministrazione.
                          <br />
                          Appena confermato questo status, diventerà immediatamente accessibile l'area specifica per la generazione dei permessi.
                        </TextUser>
                      </div>
                    </div>
                  </div>
                </div>
                <div key="2" id="reseller-information-container-mdlg" className="information-container hidden-md hidden-lg" style={{ display: "flex", justifyContent: "center" }}>
                  <div style={{ width: "85%" }}>
                    <div style={{ marginLeft: 10 }}>
                      <TextUser bold>
                        <FontAwesomeIcon size="2x" className="sectionIcons" icon={solidIcons.faUserTie} />
                        &nbsp;
                        AREA PERMESSI RIVENDITORE NON ANCORA DISPONIBILE
                      </TextUser>
                      <TextUser>
                        Il Vostro status di RIVENDITORE deve essere confermato dall'amministrazione.
                        <br />
                        Appena confermato questo status, diventerà immediatamente accessibile l'area specifica per la generazione dei permessi.
                      </TextUser>
                    </div>
                  </div>
                </div>
              </div>
            ]
            :
            [
              <div key="3" id="user-information-containerxssm" className="information-container hidden-xs hidden-sm" style={{ display: "flex", justifyContent: "center" }}>
                <div style={{ width: "85%" }}>
                  <div style={{ marginTop: 15, marginBottom: 10, display: "flex", alignItems: "center" }}>
                    <span className="threesom-span">
                      <FontAwesomeIcon size="2x" className="sectionIcons" icon={solidIcons.faCaretLeft} />
                    </span>
                    <div>
                      <TextUser bold>AREA PERMESSI</TextUser>
                      <TextUser>
                        In questa sezione puoi richiedere i permessi disponibili per la tua categoria utente.<br /><br />
                      </TextUser>
                    </div>
                  </div>
                </div>
              </div>
            ]
          }
          {news != null && news.length > 0 &&
            <div className="information-container">
              <div style={{ marginLeft: 10 }}>
                <div style={{ marginTop: "5em", marginBottom: 10 }}>
                  <TextUser bold>COMUNICAZIONI</TextUser>
                  <div style={{ margin: 0 }}>

                    <Carousel
                      autoPlay
                      interval={8000}
                      infiniteLoop
                      stopOnHover
                      emulateTouch
                      showThumbs={false}
                    >
                      {news.map(n =>
                        <div key={"news-" + n} className={"boxLabel " + (getNewsStyle(n))}>
                          {n.thumbImage == null ? (
                            <div>
                              {n.startDate && moment(n.startDate).format('L')} - {n.title}<br /><br />
                              {getHtmlMsgFromString(n.content)}<br /><br /><br />
                            </div>
                          ) : (
                            <img style={{ width: '100%', height: '100%', objectFit: 'contain' }} src={n.thumbImage}></img>
                          )}
                        </div>
                      )}
                    </Carousel>

                  </div>
                </div>
              </div>
            </div>
          }

        </div>
      </div>

    )

  }

}


export default connect(mapStateToProps, mapDispatchToProps)(UserAreaMarks)
