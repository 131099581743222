import React, {Component} from 'react'
import './BackOfficeUserScreen.css'
import ReactTable from 'react-table'
import { Oval } from 'react-loader-spinner'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import  * as solidIcons  from '@fortawesome/free-solid-svg-icons'
import { getGridHeight } from 'libs/utils'
import Parser from 'html-react-parser';
import { getMarkZone } from 'libs/marks' 
import CategoriesFilter from 'components/ReactTable/Filters/CategoriesFilter'

export default class BackOfficeUserScreen extends Component {

  /**
   * ----------------------------------------------------------------
   * @param {*} props 
   */
  constructor (props) {
    super(props)

    this.state = {
      maxHeight: 0,
      columns: [
        {
          Header: 'ID',
          accessor: 'id',
          filterable: true,
          sortable: false,
          width: 80,
          Cell: (row) => {
            return (<div>{row.value}</div>)
          }
        },
        {
          Header: 'Nome',
          accessor: 'firstName',
          filterable: true,
          sortable: false,
          minWidth: 100,
          Cell: (row) => {
            return (<div>{row.value}</div>)
          }
        },
        {
          Header: 'Cognome',
          accessor: 'lastName',
          filterable: true,
          sortable: false,
          minWidth: 100,
          Cell: (row) => {
            return (<div>{row.value}</div>)
          }
        },        
        {
          Header: 'Email',
          accessor: 'email',
          filterable: true,
          sortable: false,
          minWidth: 200,
          Cell: (row) => {
            return (<div>{row.value}</div>)
          }
        },
        {
          Header: 'Telefono',
          accessor: 'phone',
          filterable: true,
          sortable: false,
          Cell: (row) => {
            return (<div>{row.value}</div>)
          }
        },
        {
          Header: 'Veicoli',
          accessor: 'Vehicles',
          filterable: true,
          sortable: false,
          minWidth: 130,
          Cell: (row) => {

            var vehicles = ''

            row.value.map((item, index) => {
              
              vehicles = vehicles + item.plate_number
              if (index < row.value.length - 1) {
                vehicles = vehicles + '\n '
              }
            })

            return <div>{Parser( vehicles.replace(/\n/g,"<br/>"))}</div>
          }
        },
        // {
        //   Header: 'Permessi',
        //   accessor: 'Marks',
        //   filterable: true,
        //   sortable: false,
        //   minWidth: 150,
        //   Cell: (row) => {
        //     return (<div>{row.value.map((item, index) => {
        //       let string = ''
        //       string = string + item.id
        //       if (index < row.value.length - 1) {
        //         string = string + ', '
        //       }
        //       return string
        //     })}</div>)
        //   }
        // },
        {
          Header: 'Zone',
          accessor: '',
          filterable: false,          
          sortable: false,
          minWidth: 150,

          Cell: (row) => {        
                        
            var areaList        = null
            var areaArray       = []
            var marks           =  row.value.Marks
            var user            = row.value
            
            marks.map( mark => {

              var area = getMarkZone(user,mark)

              if ( area ) {
                
                if ( ! areaArray[area.id] ) {
                  areaArray[area] = true

                  if ( areaList != null ) {
                    areaList = areaList + "\n" + area
                  }
                  else {
                    areaList = area
                  }
                }
                
              }
            })

            if ( !areaList )
              areaList = ""

            return <div>{Parser( areaList.replace(/\n/g,"<br/>"))}</div>
          }
        },
        {
          Header: 'Categorie',
          accessor: 'Categories',
          filterable: true,          
          sortable: false,
          minWidth: 300,

          Cell: (row) => {

            var categorie = ''

            row.value.map((item, index) => {              
              categorie = categorie + item.name
              if (index < row.value.length - 1) {
                categorie = categorie + '\n'
              }              
            })
            
            return <div>{Parser( categorie.replace(/\n/g,"<br/>"))}</div>
          },
          Filter: ({ filter, onChange }) => <CategoriesFilter filter={filter} onChange={onChange} />
        },
        {
          Header: 'Codice Fiscale',
          accessor: 'fiscalCode',
          minWidth: 200,
          filterable: true,
          sortable: false,
          Cell: (row) => {
            return (<div>{row.value}</div>)
          }
        }
      ]
    }

    if ( props.mode === 'user') {
      //console.log("USER MODE")
      this.state.columns = this.state.columns.filter( item => { return item.Header !== 'Zone' } )
    }
    else {
      //console.log("WAITING LIST MODE")
    }

    this.onFetchData   = this.onFetchData.bind(this)
    this.getTotalPages = this.getTotalPages.bind(this)
    this.handleResize  = this.handleResize.bind(this)
  }

  /**
   * --------------------------------------------------------------
   * @param {*} mark 
   * @returns 
   */
  getMarkZone(user,mark) {
    let zonesFiltered

    if (mark.Category.parkingInDifferentPlace) {
      zonesFiltered = mark.MarkType.Areas.filter(mark2 => {
        return mark2.id === user.parkSpaceAddress.areaId
      })
    } 
    else {
      zonesFiltered = mark.MarkType.Areas.filter(item => {
        if ( !mark.userCheck ) {
          return true
        }
        else if (user.residenceArea) {
          return mark.id === user.residenceArea.id
        } 
        else if (user.workArea) {
          return mark.id === user.workArea.id
        }                      
      })

      if ( zonesFiltered == null || zonesFiltered.length <= 0 ) {
        zonesFiltered = mark.MarkType.Areas
      }

    }

    return zonesFiltered.length > 0 ? zonesFiltered[0].code : '' 
  }

  /**
   * ----------------------------------------------------------------
   */
  componentDidMount () {    
    window.addEventListener('resize', this.handleResize)
    this.setState({ maxHeight : getGridHeight() })
  }

  /**
   * ----------------------------------------------------------------
   */
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  /**
   * ----------------------------------------------------------------
   */
  handleResize ( ) {        
    this.setState({ maxHeight : getGridHeight() })    
  }

  /**
   * ----------------------------------------------------------------
   * @param {*} state 
   * @param {*} instance 
   */
  onFetchData (state, instance) {
    let filterObj = {}
    for (let i in state.filtered) {
      let f = state.filtered[i]
      if (typeof f.value === 'string') {
        filterObj[f.id] = f.value
      }
    }

    let sortObj = {}
    for (let i in state.sorted) {
      let s = state.sorted[i]
      sortObj[s.id] = s.desc ? 'desc' : 'asc'
    }

    this.props.fetchUsers(filterObj, state.pageSize, state.page)
  }

  /**
   * ----------------------------------------------------------------
   */
  getTotalPages () {
    const {pagination} = this.props

    if (!pagination) {
      return 0
    }

    if (pagination.total === 0) {
      return 0
    }

    return Math.ceil(pagination.total / pagination.limit)
  }

  /**
   * ----------------------------------------------------------------
   */
  render () {
    const {
      columns,
      maxHeight
    } = this.state
    return ( 
      <div>                              
        {         
        <ReactTable        
          getTdProps={(state, rowInfo, column, instance) => {
            return {
              onClick: (e, handleOriginal) => {                
                if (!rowInfo) {
                  return
                }
                
                this.props.onRowClick(rowInfo.index, rowInfo.original)
              }
            }
          }}          
          manual
          filterable
          loading={this.props.loading}
          noDataText={'Nessun Utente Presente'}
          onFetchData={this.onFetchData}
          pages={this.getTotalPages()}
          className="-highlight"
          data={this.props.users ? this.props.users : []}
          style={{maxHeight: maxHeight - 20 }}
          defaultPageSize={100}
          nextText={<FontAwesomeIcon  className="mainIconsNoAction"  size="2x" icon={solidIcons.faArrowRight} />}
          previousText={<FontAwesomeIcon  className="mainIconsNoAction"  size="2x" icon={solidIcons.faArrowLeft} />}
          loadingText={<Oval secondaryColor="#02afff"  color="#174c88" height={40} width="100%" />}
          rowsText="elementi"
          ofText="di"
          pageText="Pagina"
          columns={columns} />
        }
      </div>

    )
  }
}
