import React, { Component } from 'react';
import { useSelector } from "react-redux";
import { reduxForm } from 'redux-form';
import ReactTable from 'react-table';
import SostaOnlineApiService from 'services/SostaOnlineApiService';
import { OverlayTrigger, Tooltip, Form, FormGroup, ControlLabel, FormControl, Button, Col } from 'react-bootstrap';
import { Oval } from 'react-loader-spinner';
import Modal from 'react-responsive-modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import  * as solidIcons  from '@fortawesome/free-solid-svg-icons'

class BackofficePassagesTime extends Component {

  stateInitValues = {
    selectedPassagesTime: [],
    show: false,
    dataValue: {}
  }

  /**
   * --------------------------------------------------------------------------
   * @param {*} props 
   */
  constructor (props) {    
    super(props);
    this.state = {
      ... this.stateInitValues,
      daysToWeek: ["lunedì", "martedì", "mercoledì", "giovedì", "venerdì", "sabato", "domenica"],      
      passagesTime: [],
      columns: [
        {
          Header: () => <div className="pull-left" style={{ marginLeft: "1em" }}><strong>Id</strong></div>,
          accessor: 'id',
          filterable: false,
          sortable: true,
          width: 60,
          Cell: (row) => {
            return (
              <OverlayTrigger placement="top" overlay={<Tooltip id={'tooltip-address'}>Doppio click per selezionare l'indirizzo</Tooltip>}>
              <div className="pull-left" style={{ marginLeft: "1em" }}>
                {row.value}                
              </div>
              </OverlayTrigger>
            )
          }
        },
        {
          Header: () => <div className="pull-left" style={{ marginLeft: "1em" }}><strong>Varco</strong></div>,
          accessor: 'passagesId',
          filterable: false,
          sortable: true,
          width: 70,
          Cell: (row) => {
            return (
              <OverlayTrigger placement="top" overlay={<Tooltip id={'tooltip-address'}>Doppio click per selezionare l'indirizzo</Tooltip>}>
              <div className="pull-left" style={{ marginLeft: "1em" }}>
                {row.value}                
              </div>
              </OverlayTrigger>
            )
          }
        },
        {
          Header: () => <div className="pull-left" style={{ marginLeft: "1em" }}><strong>Inizio orario</strong></div>,
          accessor: 'dataStartPassage',
          filterable: false,
          sortable: true,
          width: 110,
          Cell: (row) => {
            return (
              <OverlayTrigger placement="top" overlay={<Tooltip id={'tooltip-address'}>Doppio click per selezionare l'indirizzo</Tooltip>}>
              <div className="pull-left" style={{ marginLeft: "1em" }}>
                {row.value}                
              </div>
              </OverlayTrigger>
            )
          }
        },
        {
          Header: () => <div className="pull-left" style={{ marginLeft: "1em" }}><strong>Fine orario</strong></div>,
          accessor: 'dataEndPassage',
          filterable: false,
          sortable: true,
          width: 100,
          Cell: (row) => {
            return (
              <OverlayTrigger placement="top" overlay={<Tooltip id={'tooltip-address'}>Doppio click per selezionare l'indirizzo</Tooltip>}>
              <div className="pull-left" style={{ marginLeft: "1em" }}>
                {row.value}                
              </div>
              </OverlayTrigger>
            )
          }
        },
        {
          Header: () => <div className="pull-left" style={{ marginLeft: "1em" }}><strong>Giorno della settimana</strong></div>,
          accessor: 'dayWeek',
          filterable: false,
          sortable: true,
          width: 180,
          Cell: (row) => {
            return (
              <OverlayTrigger placement="top" overlay={<Tooltip id={'tooltip-address'}>Doppio click per selezionare l'indirizzo</Tooltip>}>
              <div className="pull-left" style={{ marginLeft: "1em" }}>
                {this.handleDaysWeek(row.value)}                
              </div>
              </OverlayTrigger>
            )
          }
        },
      ],
    }
  }

  /**
   * --------------------------------------------------------------------------
   */
  async componentDidMount () {   
    this.setState({ areaId: this.props.areaId  })         
    await this.fetchData(this.props.passageName, this.state.areaId)
  }

  /**
   * --------------------------------------------------------------------------
   */
  async componentDidUpdate (prevProps, prevState) {
    console.log('componentDidUpdate: ' + this.props.passageId);
    if (
      this.props.passageId !== prevProps.passageId
    ) {
      await this.fetchData(this.props.passageName, this.state.areaId)
      // this.destroyConnection();
      // this.setupConnection();
    }
    //await this.fetchData()
  } 
    
  /**
   * --------------------------------------------------------------------------
   */
  async fetchData(passageId, areaId) {
    
    SostaOnlineApiService.fetchPassagesTime(passageId, areaId)
    .then( result => {        
        if ( result != null ) { this.setState({ passagesTime: result }) }
    })
    .catch( error => {

        console.log("ERROR: ", error)        
        var errMsg = ""

        if ( error.error != null ) 
          errMsg = error.error
        else 
          errMsg = "Errore non definito."

        console.log("ERROR",error)        
        //this.setState({ sendErrors: errMsg })

    })
  }

  /**
   * --------------------------------------------------------------
   * @param {*} value 
   */
  async onPassagesTimeSelected(rowIndex, rowInfo){

    var selectedPassagesTime = this.state.selectedPassagesTime
    var newSelectedPassagesTime = [... selectedPassagesTime]    

    if ( rowInfo != null ) {
      var alreadySelected = await selectedPassagesTime.find( passagesTime => passagesTime.id == rowInfo.id )

      if ( alreadySelected == null ) {        
        newSelectedPassagesTime.push( rowInfo )
      }
    }

    this.setState({ selectedPassagesTime: newSelectedPassagesTime  })

    if(this.state.show == true){
      this.setState({ show: false })
    } else {
      this.setState({ show: true })
      this.setState({id: rowInfo.id})
      this.setState({passageId: rowInfo.passagesId})
      this.setState({timeStart: rowInfo.dataStartPassage})
      this.setState({timeEnd: rowInfo.dataEndPassage})
      this.setState({dayWeek: rowInfo.dayWeek})
    }    
  }

  handleTimeStartChange = (e) => {
    this.setState({timeStart: e.target.value});
  }

  handleTimeEndChange = (e) => {
    this.setState({timeEnd: e.target.value});
  }    

  handleDayWeekChange = (e) => {
    this.setState({dayWeek: e.target.value});
  } 

  handlePassagesTime = () => {

    let passageTime = {
      id: this.state.id,
      areaId: this.props.areaId,
      passagesId: this.props.passageName,
      dataStartPassage: this.state.timeStart,
      dataEndPassage: this.state.timeEnd,
      dayWeek: this.state.dayWeek,
    }   

    if (!this.state.id) {

      SostaOnlineApiService.createPassagesTime(passageTime)
      .then( result => {        
        if ( result != null ) { 
          //this.setState({ passagesTime: result }) 
          this.closeModal()
        }        
        this.fetchData(this.props.passageName, this.state.areaId);
        })
      .catch( error => {

          console.log("ERROR: ", error)        
          var errMsg = ""

          if ( error.error != null ) 
            errMsg = error.error
          else 
            errMsg = "Errore non definito."

          console.log("ERROR",error)        
          //this.setState({ sendErrors: errMsg })

      })

    } else {

      SostaOnlineApiService.updatePassagesTime(passageTime)
      .then( result => {        
        if ( result != null ) {        
          this.closeModal()
        }        
        this.fetchData(this.props.passageName, this.state.areaId);
        })
      .catch( error => {

        console.log("ERROR: ", error)        
        var errMsg = ""

        if ( error.error != null ) 
          errMsg = error.error
        else 
          errMsg = "Errore non definito."

        console.log("ERROR",error);
      })

    }

  }

  deletePassageTime = () => {

    SostaOnlineApiService.deletePassagesTime(this.state.id)
    .then( result => {      
      if ( result != null ) {        
        this.closeModal()
      }        
      this.fetchData(this.props.passageName, this.state.areaId);
      })
    .catch( error => {
      console.log("ERROR: ", error)        
      var errMsg = ""

      if ( error.error != null ) 
        errMsg = error.error
      else 
        errMsg = "Errore non definito."

      console.log("ERROR",error)
    })    

  }

  handleDayWeekSelect = (value) => {
    this.setState({dayWeek: value})    
  }

  handleDaysWeek = (dayId) => {       
    let response = (dayId != null ? this.state.daysToWeek[dayId] : "tutti i giorni")    
    return response;
  }

  formatTimePassagesTimes = (time) => {
    if (time.lengt == 8) {
      return time.substring(0, 4);
    }
    return time;
  }

  openModal = () => {    
    this.setState({ show: true })
    this.setState({ id: null })
    this.setState({ passageId: this.props.passageId })
    this.setState({ timeStart: null })
    this.setState({ timeEnd: null })
    this.setState({ dayWeek: null })
  }

  closeModal() {
    this.setState({ show: false })
  }

  render () {

    const { passageId, passageName, areaId } = this.props
    const { passagesTime, selectedRow, columns, show} = this.state;    
    let passagesTimeArray = passagesTime.data;

    return (
       
        <React.Fragment>     
          <div className="row" style={{marginBottom: '1.5em', marginTop: '1em' }}>
              
              <div className= "col-md-5 text-center"  style={{marginBottom: '1em' }}></div>            
              <div className= "col-md-7 text-center"  style={{marginBottom: '1em' }}>
              
                <div>
                  
                  {show && (                    
                    <Modal
                      open={true}
                      closeOnEsc={false}
                      showCloseIcon={false}
                      closeOnOverlayClick={false}
                      classNames={{modal: 'app-modal-container-medium'}}  
                      animationDuration={500}
                      center
                    >
                      <h4 className="app-modal-title">
                          <div style={{ flex: 80, textAlign : 'center' }}><strong>FASCE ORARIE VARCO {this.state.passageId}</strong></div>
                          <div style={{ flex: 10, textAlign : 'right'}}>
                              <FontAwesomeIcon  onClick={ evt => this.closeModal() } className="mainIconsRev"  size="1x" icon={solidIcons.faXmark} />
                          </div>
                      </h4>

                      <div className="col-md-12">
                        <div className="app-modal-body">
                          <div className="row" style={{ padding: "10px"}}>
                            <form>
                              <div className="col-md-3">                       
                                <ControlLabel>Orario di inizio</ControlLabel>{' '}
                                <input type="time" value={this.state.timeStart} onChange={this.handleTimeStartChange} />{' '}
                              </div>
                              <div className="col-md-3">
                                <ControlLabel>Orario di fine</ControlLabel>{' '}
                                <input  type="time" value={this.state.timeEnd} onChange={this.handleTimeEndChange}/>{' '}
                              </div>
                              <div className="col-md-6">
                                <ControlLabel>Giorno della settimana</ControlLabel>
                                {/* <input  type="text" value={this.handleDaysWeek(this.state.dayWeek)} onChange={this.handleDayWeekChange}/>{' '} */}
                                
                                <select 
                                  name="passageName" 
                                  className="form-control"
                                  value={this.state.dayWeek != null ? this.state.dayWeek : "#"}
                                  //onChange={ e => { e.target.value } }
                                  onChange={ e => { this.handleDayWeekSelect(e.target.value) } }
                                  
                                >
                                  <option value="#"> TUTTI I GIORNI </option>
                                  { this.state.daysToWeek.map( dayToWeek =>
                                    <option key={this.state.daysToWeek.indexOf(dayToWeek)} value={this.state.daysToWeek.indexOf(dayToWeek)} {...{}} > {this.handleDaysWeek(this.state.daysToWeek.indexOf(dayToWeek))} </option>
                                  )}
                                </select>


                              </div>                         
                              { this.state.id && (                  
                                <>
                                  <div className="col-md-3 mb-3">
                                    <ControlLabel>Eliminazione fascia</ControlLabel><br />
                                    <Button bsStyle="danger" bsSize="small" value={this.state.id} onClick={this.deletePassageTime}>ELIMINA</Button>
                                  </div>
                                  <div className="col-mt-3">
                                    <Button bsStyle="primary" bsSize="large" block onClick={this.handlePassagesTime}>MODIFICA FASCIA ORARIA</Button>
                                  </div>
                                </>
                              )}
                              { !this.state.id && (                                
                                <div>
                                  <br/><br/><br/>
                                  <Button bsStyle="primary" bsSize="large" block onClick={this.handlePassagesTime}>CREA FASCIA ORARIA</Button>
                                </div>
                              )}
                            </form>
                          </div>                            
                        </div>
                                            
                          
                      </div>

                    </Modal>
                    )
                  }               
                </div>
       
              <label style={{marginBottom: '0.5em' , marginTop: '0.5em' }}>FASCE ORARIE VARCHI</label>
              {passageId &&
                <Button className="ml-3 mb-3" bsStyle="primary" bsSize="medium" onClick={this.openModal}>Crea nuova fascia</Button>
              }
                                              
              {/* <Button className="btn mainBtn" onClick={ () => this.openModal() }>
                <FontAwesomeIcon className="mainIconsRevNoAction" size="1x" />&nbsp;&nbsp;Crea nuova fascia
              </Button>         */}
              <ReactTable       
                getTdProps={(state, rowInfo, column, instance) => {

                  const isActive = rowInfo && selectedRow == rowInfo.index
                  const activeStyle = {}

                  if (isActive) {
                    activeStyle.background = '#f5f5f5'
                    activeStyle.color = 'black'                      
                  }

                  return {
                    style: { ...activeStyle },
                    onDoubleClick: (e, handleOriginal) => { 
                      if (rowInfo != null ) {
                        this.onPassagesTimeSelected(rowInfo.index, rowInfo.original);                        
                      }
                    }
                  }
                }}              
                manual
                filterable
                //loading={loading}
                noDataText={'Nessun Indirizzo Presente'}
                //onFetchData={null}  
                className="-highlight"
                data={ passagesTimeArray }
                style={{ maxHeight: "25em" }}                                    
                loadingText={<Oval secondaryColor="#02afff"  color="#174c88" height={40} width="100%" />}
                showPaginationBottom={false}                  
                columns={columns} 
                />

              </div>

          </div>
        </React.Fragment>    
    ) 
  }
  

}

export default reduxForm({ form: 'BackofficePassagesTime'})(BackofficePassagesTime)