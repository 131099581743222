import React, {Component} from 'react'
import { OverlayTrigger, Popover }   from 'react-bootstrap'
import SplashBtn       from '../../components/SplashBtn'
import SplashLoginBtn       from '../../components/SplahsLoginBtn'
import LoginForm       from '../../components/LoginForm'
import HeaderComponent from '../../components/HeaderComponent'
import './Splash.css'
import { getWindowHeight } from 'libs/utils'
import { Carousel } from 'react-responsive-carousel'
import SpidIcon             from '../../assets/spid-ico-circle-bb.png'
import CieIcon             from '../../assets/cie-ico-circle-bb.png'
import SpidAgidLogo         from  '../../assets/spid-agid-logo-lb.png'
import CieMinIntLogo         from  '../../assets/0854_mdi_ml_tricolore.png'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import SostaOnlineApiService from 'services/SostaOnlineApiService'
import { NEWS_SECTIONS, NEWS_TYPES, getHtmlMsgFromString, getNewsStyle } from 'libs/news'
import moment from 'moment'

import {connect} from "react-redux";
import { size } from 'lodash'

const mapStateToProps = (state) => { 
  return {
    state: state.auth
  }
}

const LogoApp = "/cities/"+process.env.REACT_APP_CITY.toLowerCase()+'/logo-app.png'

// ----------------------------------------------------------------
//
//
class Splash extends Component {

  // ----------------------------------------------------------------
  //
  //
  constructor(props) {
    super(props)

    this.state = {
      minHeight: 0,
      news: []
    }

    this.handleResize = this.handleResize.bind(this)
  }

  /**
   * --------------------------------------------------------------
   * @param {*} prevProps 
   */
  async componentDidUpdate(prevProps) {
    if ( prevProps != this.props ) {
      var news = await SostaOnlineApiService.fetchNewsMe(NEWS_SECTIONS.USERLOGIN.value)      
      this.setState({ news: news.data})
    }
  }


  // ----------------------------------------------------------------
  //
  //
  async componentDidMount () {    
    window.addEventListener('resize', this.handleResize)
    this.setState({ minHeight : getWindowHeight()  })
    var news = await SostaOnlineApiService.fetchNewsMe(NEWS_SECTIONS.USERLOGIN.value)    
    this.setState({ news: news.data})
  }

  // ----------------------------------------------------------------
  //
  //
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  // ----------------------------------------------------------------
  //
  //
  handleResize ( ) {        
    this.setState({ "minHeight" : getWindowHeight() })    
  }
  

  // ----------------------------------------------------------------
  //
  //

  registerEmailPopOver() {      
    return (
      <OverlayTrigger 
        placement="top" 
        trigger="click"
        overlay={            
            <Popover id="popover-positioned-top" title="REGISTRAZIONE CON EMAIL" >             
              Per <strong>registrarsi</strong>: <br />
              1) cliccare sul pulsante<br /><strong>"REGISTRATI CON EMAIL".</strong><br />
              2) successivamente selezionare il titolo di sosta di interesse.<br />
              3) compilare i relativi form.<br/> 
              4) convalidare attraverso la email ricevuta di conferma.<br/><br/>     
              <strong>Per la registrazione è richiesto un indirizzo email.</strong><br/>                                      
            </Popover>                        
        }>
        <span className="question-icon glyphicon glyphicon-question-sign icon-question-spid ">{}</span>
      </OverlayTrigger>
    )    
}

  spidPopOver() {      
      return (
        <OverlayTrigger 
          placement="top" 
          trigger="click"
          overlay={            
              <Popover id="popover-positioned-top" title="SPID" >                
                <strong>SPID</strong> è il sistema di accesso che consente di utilizzare, 
                con un'identità digitale unica, i servizi online della Pubblica Amministrazione e dei privati accreditati.<br/>                
                Se sei già in possesso di un'identità digitale, accedi con le credenziali del tuo gestore.<br/>
                Se non hai ancora una'identità digitale, richiedila ad uno dei gestori.<br/><br/>
                <a href="https://www.spid.gov.it/" target="_blank">Maggiori informazioni su SPID</a><br/>
                <a href="https://www.spid.gov.it/cos-e-spid/come-attivare-spid/" target="_blank">Non hai SPID ?</a>
                <br/><br/>                
                <img className="spid-agid-icon" src={SpidAgidLogo}></img>                                
              </Popover>                        
          }>
          <span className="question-icon glyphicon glyphicon-question-sign icon-question-spid ">{}</span>
        </OverlayTrigger>
      )    
  }

  ciePopOver() {    
    return (
      <OverlayTrigger 
        placement="top" 
        trigger="click"
        overlay={            
            <Popover id="popover-positioned-top" title="CIE" >                
              <strong>CIE</strong> carta di indentità elettronica è il sistema di accesso che consente di utilizzare, 
              con un'identità digitale unica, i servizi online della Pubblica Amministrazione e dei privati accreditati.<br/>                
              Se sei già in possesso di un'identità digitale, accedi con le credenziali del tuo gestore.<br/>
              Se non hai ancora una'identità digitale, richiedila ad uno dei gestori.<br/><br/>
              <a href="https://www.cartaidentita.interno.gov.it/" target="_blank">Maggiori informazioni su CIE</a><br/>
              <a href="https://www.cartaidentita.interno.gov.it/pgic/attivazione-credenziali-puk" target="_blank">Non hai CIE ?</a>
              <br/><br/>                
              <img className="cie-minint-icon" src={CieMinIntLogo}></img>
            </Popover>                        
        }>
        <span className="question-icon glyphicon glyphicon-question-sign icon-question-spid ">{}</span>
      </OverlayTrigger>
    )

  }

  render () {
    const {onPasswordForgot, onSubmit, onRegisterClick, onSpidClick, onCieClick, onSendConfirmEmail} = this.props
    const {minHeight, news} = this.state

    return (<div>
      <HeaderComponent />
      <div className="main-container container" style={{ minHeight: minHeight-30 }}>
        <div className="row" >

          <div className="col-md-6" >
            <div className="panel panel-default splash-registration-div" style={{ minHeight:"40em"}}>
              <div className="splash-registration">
                <a href={"http://"+process.env.REACT_APP_MANAGER_URL} target="_new" alt="" className="btn-social btn-outline"><img alt="" className="splash-registration-logo" src={LogoApp} /></a>
              </div>
              
                {process.env.REACT_APP_CITYL === 'Faenza' ? 
                (
                  <div className="splash-registration" style={{padding: 10}}>
                    Benvenuto nel portale on-line di iscrizione in Lista Bianca ZTL della Città di {process.env.REACT_APP_CITYL}.
                  </div>
                ) : 
                (
                  <div className="splash-registration" style={{padding: 10}}>
                    Benvenuto nello sportello on-line del Piano Sosta della città di {process.env.REACT_APP_CITYL}.
                  </div>
                )}       
              
              {/* <div className="splash-registration" style={{padding: 10}}>
                Benvenuto nello sportello on-line del Piano Sosta della città di {process.env.REACT_APP_CITYL}.                
              </div> */}
              <div className="splash-registration" style={{padding: '0 10px'}} >
                {news != null && news.length > 0 && 
                  <Carousel
                    autoPlay
                    interval={8000}
                    infiniteLoop
                    stopOnHover
                    emulateTouch
                    showThumbs={false}
                  >
                  { news.map(  n => 
                      <div key={"news-"+n} className={"boxLabel " + (getNewsStyle(n))} style={{padding: n.thumbImage != null ?'0' : '', backgroundColor: n.thumbImage!= null ? 'white': 'rgb(239, 233, 233)'}}>
                        <div>
                          {n.startDate && moment(n.startDate).format('L')} - {n.title}<br/><br/>
                          { getHtmlMsgFromString(n.content)}<br/><br/><br/>
                        </div>
                        {/* Decommentare quando verrà completata la funzionalità ADV
                        {n.thumbImage == null ? (
                        <div>
                          {n.startDate && moment(n.startDate).format('L')} - {n.title}<br/><br/>
                          { getHtmlMsgFromString(n.content)}<br/><br/><br/>
                        </div>
                        ):(
                          <img 
                            style={{width:'100%', height:'100%', objectFit:'contain'}} 
                            src={n.thumbImage}>
                          </img>
                        )} */}
                      </div>     
                  )} 
                </Carousel>
                }
              </div>              
              <div className="splash-registration" >
                <div>                  
                  <div style={{color: "#34495e"}}>SELEZIONA IL PULSANTE DI REGISTRAZIONE DI TUO INTERESSE</div>
                  <div>&nbsp;</div>
                  {this.props.state.registerByEmailEnable &&               
                    <div style={{display: "flex", width:"70%", justifyContent : "space-between", margin: "auto"}}>
                      <SplashLoginBtn onClick={onRegisterClick} starticon={SpidIcon} classstarticon="spid-button-icon" fontWeight="bold">
                        REGISTRATI CON EMAIL
                        <div></div>
                      </SplashLoginBtn>
                      {this.registerEmailPopOver()}  
                    </div>
                  }
                </div>                           
                { this.props.state.spidEnable && 
                  <div>
                    <div>&nbsp;</div>
                    <div style={{display: "flex", width:"70%", justifyContent : "space-between", margin: "auto"}}>
                        <SplashLoginBtn onClick={onSpidClick} starticon={SpidIcon} classstarticon="spid-button-icon" fontWeight="bold">
                          REGISTRATI CON SPID
                          <div></div> 
                        </SplashLoginBtn>
                        {this.spidPopOver()}
                    </div>
                    <div >&nbsp;</div>
                    <div style={{display: "flex", width:"70%", justifyContent : "space-between", margin: "auto"}}>
                        <SplashLoginBtn onClick={onCieClick} starticon={CieIcon} classstarticon="spid-button-icon" fontWeight="bold">
                          REGISTRATI CON CIE
                          <div></div> 
                        </SplashLoginBtn>
                        {this.ciePopOver()}
                    </div>
                    <div >&nbsp;</div>
                  </div>
                }
              </div>
            </div>

            <div className="row text-center align-top" style={{marginLeft: 10, marginRight: 10, marginBottom: 50}}>
              <span className="pas" >
                <a onClick={() => onSendConfirmEmail()}>
                  <i className="glyphicon glyphicon-lock">{}</i> Non hai ricevuto l'email di conferma registrazione? Clicca qui
                </a>                
              </span>
            </div>
            <br/>
          </div>

          <div className="col-md-4 col-md-offset-1" >
            <LoginForm
              onPasswordForgot={onPasswordForgot}
              onSubmit={onSubmit} 
              onSpidClick={onSpidClick}
              onCieClick={onCieClick}
              />
          </div>

        </div>
      </div>
    </div>
    )
  }
}

export default (connect(mapStateToProps)(Splash))
